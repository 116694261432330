.root {
  display: flex;
}

.main {
  /* background-color: var(--base-white); */
  overflow-x: hidden;
  min-height: calc(100vh - 64px);
  flex-grow: 1;
  background-color: #fafafa !important;
}
