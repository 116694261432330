@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap);
:root {
  --darker-blue: #21273c;
  --dark-blue: #233861;
  --light-blue: #7993c2;
  --light-gradient: linear-gradient(
    to right,
    var(--dark-blue),
    var(--light-blue)
  );
  --dark-gradient: linear-gradient(45deg, var(--dark-blue), var(--darker-blue));
}

body {
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  height: 100vh;
  overflow-x: hidden;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-attachment: fixed;
  background-color: rgba(35, 56, 97, 1);
}

body.noScroll {
  /* overflow-y: hidden; */
}

body.blank {
  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

.otpContainer {
  margin: auto;
}

.otpInput {
  width: 3rem !important;
  height: 3rem;
  margin: 0.3rem;
  font-size: 2rem;
  color: gray;
  text-align: center;
  border-radius: 4px;
  border: 1px solid gray;
  outline: none;
}

.otpInput:focus {
  border: 2px solid rgb(35, 56, 97);
}

/*  Add breakpoint for iPhone */
@media only screen and (max-width: 375px) {
  .otpInput {
    width: 1.5rem !important;
    height: 1.5rem;
    font-size: 1rem;
    padding: 8px;
  }
}

.AppTemplate_root__1eqSc {
  display: flex;
}

.AppTemplate_main__OMYEf {
  /* background-color: var(--base-white); */
  overflow-x: hidden;
  min-height: calc(100vh - 64px);
  flex-grow: 1;
  background-color: #fafafa !important;
}

